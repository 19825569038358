export enum Permission {
    ManageAdmins = 1,
    Translations = 2,
    Design = 3,
    RecorderTemplates = 4,
    LogInAsUser = 5,
    Pricing = 6,
    Content = 7,
}

export type PermissionKey = keyof typeof Permission;

export const permissionKeys: PermissionKey[] = Object.keys(Permission).filter(
    (key) => isNaN(Number(key))
) as PermissionKey[];
