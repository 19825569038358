"use client";

import {
    DD_PRESENTATION_COUNT,
    DD_SYLLABUS_COUNT,
    DD_VIDEO_COUNT,
} from "@/lib/initDatadog";
import { datadogRum } from "@datadog/browser-rum";
import { Session, User } from "next-auth";
import { createContext, useContext, useEffect } from "react";

export interface AuthContextValue {
    user: User | null;
}

export const AuthContext = createContext<AuthContextValue>({
    user: null,
});

export const useAuth = () => useContext(AuthContext);

export interface AuthProviderProps {
    children: React.ReactNode;
    session: Session | null;
}

export const AuthProvider: React.FunctionComponent<AuthProviderProps> = ({
    children,
    session,
}) => {
    useEffect(() => {
        if (session?.user) {
            const user = session.user;

            datadogRum.setUser({
                id: user.id,
                name: user.name || undefined,
                email: user.email || undefined,
                [DD_PRESENTATION_COUNT]: 0,
                [DD_SYLLABUS_COUNT]: 0,
                [DD_VIDEO_COUNT]: 0,
            });
        }
    }, [session]);

    return (
        <AuthContext.Provider
            value={{
                user: session?.user || null,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
