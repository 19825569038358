"use client";

import { TolgeeBase } from "./shared";
import { TolgeeProvider, useTolgeeSSR } from "@tolgee/react";
import { useRouter } from "next/navigation";
import { useEffect, useMemo } from "react";
import { hasPermission } from "@/features/admin/utils";
import { FormatIcu } from "@tolgee/format-icu";
import { useAuth } from "@/features/auth/hooks/useAuth";

type Props = {
    locales: any;
    locale: string;
    children: React.ReactNode;
};

export const TolgeeNextProvider = ({ locale, locales, children }: Props) => {
    const { user } = useAuth();

    const tolgee = useMemo(() => {
        const tolgee = TolgeeBase(user).use(FormatIcu()).init();

        if (user && hasPermission(user, "Translations")) {
            if (!process.env.NEXT_PUBLIC_TOLGEE_API_KEY) {
                console.log("tolgee api key is missing");
                return tolgee;
            }
            import("@tolgee/web/tools").then((module) => {
                tolgee.addPlugin(module.InContextTools());
            });
        }

        return tolgee;
    }, [user]);

    // synchronize SSR and client first render
    const tolgeeSSR = useTolgeeSSR(tolgee, locale, locales);
    const router = useRouter();

    useEffect(() => {
        const { unsubscribe } = tolgeeSSR.on("permanentChange", () => {
            // refresh page when there is a translation update
            router.refresh();
        });

        return () => unsubscribe();
    }, [tolgeeSSR, router]);

    return (
        <TolgeeProvider tolgee={tolgeeSSR} options={{ useSuspense: false }}>
            {children}
        </TolgeeProvider>
    );
};
