"use client";

import { hasPermission } from "@/features/admin/utils";
import { useAuth } from "@/features/auth/hooks/useAuth";
import React, {
    createContext,
    useContext,
    useMemo,
    useState,
    useEffect,
} from "react";
import { useKeyPress } from "react-use";

interface AltContextType {
    canTranslate: boolean;
}

const AltContext = createContext<AltContextType | undefined>(undefined);

interface AltProviderProps {
    children: React.ReactNode;
}

export default function AltProvider({ children }: AltProviderProps) {
    const { user } = useAuth();
    const isAltPressed = useKeyPress("Alt");
    const [canTranslate, setCanTranslate] = useState(false);

    useEffect(() => {
        if (
            isAltPressed[0] && // check if Alt key is currently pressed
            user &&
            hasPermission(user, "Translations")
        ) {
            if (!process.env.NEXT_PUBLIC_TOLGEE_API_KEY) {
                console.log("tolgee api key is missing");
                setCanTranslate(false);
            } else {
                setCanTranslate(true);
            }
        } else {
            setCanTranslate(false);
        }
    }, [isAltPressed, user]);

    const value = useMemo(() => ({ canTranslate }), [canTranslate]);

    return <AltContext.Provider value={value}>{children}</AltContext.Provider>;
}

export const useAltContext = (): AltContextType => {
    const context = useContext(AltContext);
    if (!context) {
        throw new Error("useAltContext must be used within an AltProvider");
    }
    return context;
};
