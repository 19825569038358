"use client";

import { QueryProvider } from "../queryProvider";
import AltProvider from "../tolgee/altProvider";
import ToastProvider from "./toastProvider";
import InitDatadog from "../initDatadog";
import { datadogEnabled } from "../utils";
import { AuthProvider } from "@/features/auth/hooks/useAuth";
import { Session } from "next-auth";
import { TolgeeNextProvider } from "../tolgee/client";

interface ProviderProps {
    children: React.ReactNode;
    session: Session | null;
    locale: string;
    locales: Record<string, any>;
}

export const GlobalProviders = ({
    children,
    session,
    locale,
    locales,
}: ProviderProps) => {
    return (
        <QueryProvider>
            {datadogEnabled && <InitDatadog />}
            <AuthProvider session={session}>
                <TolgeeNextProvider locale={locale} locales={locales}>
                    <ToastProvider />

                    <AltProvider>{children}</AltProvider>
                </TolgeeNextProvider>
            </AuthProvider>
        </QueryProvider>
    );
};
